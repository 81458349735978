<template>
  <div class="reprutina">
  <v-container fluid>
    <v-row align="center" class="list px-3 mx-auto">
      <v-col align="center"  class="d-flex" cols="12" md="12"  >
        <h1>Monitoreo Recoleccion </h1>
      </v-col>
    </v-row>
    <v-row align="center" class="list px-3 mx-auto">
      <v-col
        v-for="(item, i) in itemsTM"
        :key="i"
        :color="item.color"
        :cols="2"
      >
        <v-card class="ma-1" :color="item.color"  outlined max-width="130">
          <v-card-title>
            <h5>{{ item.columna }}</h5>
          </v-card-title>
          <v-card-subtitle class="pb-0">
            <div>{{ item.Material }} - {{ item.numcon }}</div>
            <div>{{ item.horario }}</div>
          </v-card-subtitle>
          

        </v-card>
      </v-col>

      
    </v-row>
  </v-container>
    <div v-if="showModal2">
      <Modal
        :heading="heading2"
        :text="text2"
        theme="sale"
        @close="toggleModal1"
      />
    </div>

    <v-dialog v-model="dialog" max-width="400">
      <v-card max-width="400">
        <v-img v-bind:src="image" width="100%" height="100%"></v-img>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import Modal from "@/components/Modal_image";
//const axios = require("axios");
import axios from "axios";
export default {
  name: "Repincidencias",
  components: { Modal },
  data: () => ({
    heading2: "Modal 2",
    text2: "Hello from modal 2",
    showModal1: false,
    showModal2: false,
    showModal3: true,
    click_imagen: "Click imagen",
    rutinas: [],
    textnodata: "Sin Datos",
    tablenodata: "Sin Datos",
    itemsTM: [
      {
        color: "gray",
        columna: "T3 27",
        Material: "RP Basura",
        numcon: "C1",
        horario: "07:25 - 07:55",
        imagen: false,
      },
      {
        color: "gray",
        columna: "T3 27",
        Material: "Plastico",
        numcon: "C2",
        horario: "07:25 - 07:55",
        imagen: false,
      },
      {
        color: "gray",
        columna: "T3 27",
        Material: "Carton",
        numcon: "C3",
        horario: "07:25 - 07:55",
        imagen: false,
      },
      {
        color: "#10d10d",
        columna: "U5 26",
        Material: "RP Basura",
        numcon: "C1",
        horario: "07:36:20",
        imagen: false,
      },
      {
        color: "#10d10d",
        columna: "T3 26",
        Material: "RP Basura",
        numcon: "C1",
        horario: "07:39:20",
        imagen: false,
      },
      {
        color: "#ff0000",
        columna: "T3 26",
        Material: "RP Basura",
        numcon: "C2",
        horario: "07:40:20",
        imagen: true,
      },
      {
        color: "#10d10d",
        columna: "T3 26",
        Material: "RP Basura",
        numcon: "C3",
        horario: "07:41:20",
      },
      {
        color: "#10d10d",
        columna: "T3 26",
        Material: "RP Basura",
        numcon: "C4",
        horario: "07:42:20",
      },
      {
        color: "#10d10d",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C1",
        horario: "07:48:20",
      },
      {
        color: "#10d10d",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C2",
        horario: "07:49:20",
      },
      {
        color: "#10d10d",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C3",
        horario: "07:49:50",
      },
      {
        color: "#10d10d",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C4",
        horario: "07:50:20",
      },
      {
        color: "#ff0000",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C5",
        horario: "07:50:50",
      },
      {
        color: "#10d10d",
        columna: "T3 24",
        Material: "RP Basura",
        numcon: "C6",
        horario: "07:51:20",
      },
      {
        color: "gray",
        columna: "T3 23",
        Material: "Carton",
        numcon: "C1",
        horario: "07:25 - 07:55",
      },
      {
        color: "gray",
        columna: "T3 23",
        Material: "Carton",
        numcon: "C2",
        horario: "07:25 - 07:55",
      },
      {
        color: "gray",
        columna: "T3 23",
        Material: "Carton",
        numcon: "C3",
        horario: "07:25 - 07:55",
      },
    ],
    headers: [
      { text: "Columna", value: "namecol", sortable: false },
      { text: "Contenedor", value: "nametipocontenedor", sortable: false },
      { text: "Contenido", value: "namemat", sortable: false },
      { text: "NoContenedor", value: "contenum", sortable: false },
      { text: "Fecha y Hora", value: "fechahora", sortable: false },
      { text: "Incidencia", value: "incidencia", sortable: false },
      { text: "Imagen", value: "imagen_url", sortable: false },
    ],
    title: "",
    expire: "",
    label: "Expire date",
    placeholder: "",
    rules: [(v) => !!v || "This field is required"],
    showMenu: false,
    combosample: "combo sample",
    combox: "combox",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    idarea: 0,
    areas: [],
    idcol: 0,
    columnas: [],
    id_workgroup: 0,
    workgroups: [],
    country: "",
    idconfig: 0,
    Str_imagen: "",
    dialog: false,
    image: "",
  }),

  methods: {
    view(imagen_url) {
      this.dialog = true;
      this.Str_imagen = imagen_url.substr(2, imagen_url.length - 2);
      this.image = encodeURI(
        "https://psisantamonica.mx/daimler" + this.Str_imagen
      );
    },

    toggleModal1() {
      this.showModal2 = !this.showModal2;
    },

    async getAreas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=1";

      await axios.get(str_url).then((response) => {
        this.areas = response.data;
        console.log("areas: ", this.areas);
      });
    },

    async getWorkGroup() {
      const str_url = "https://psisantamonica.mx/daimler/db/dblist_areas.php";

      await axios
        .get(str_url, {
          params: {
            request: 4,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          console.log("response.data; ", response.data);
          this.workgroups = response.data;
          console.log("work count len : ", this.columnas.length);

          this.idwork = 0;

          if (this.workgroups.length > 0) {
            this.workgroups.push({
              id_workgroup: 0,
              name: "todas los grupos",
            });

            this.workgroups.sort(function (a, b) {
              var codigoA = a.id_workgroup; // ignore upper and lowercase
              var codigoB = b.id_workgroup; // ignore upper and lowercase
              return codigoA - codigoB;
            });
            console.log("col mayor cero count len : ", this.workgroups);
          }
        });
    },

    async getColumnas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=2";

      await axios
        .get(str_url, {
          params: {
            request: 2,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          this.columnas = response.data;
          console.log("count len : ", this.columnas.length);

          this.idcol = 0;

          if (this.columnas.length > 0) {
            this.columnas.push({
              idcol: 0,
              name: "todas las Columnas",
            });

            this.columnas.sort(function (a, b) {
              var codigoA = a.idcol; // ignore upper and lowercase
              var codigoB = b.idcol; // ignore upper and lowercase
              return codigoA - codigoB;
            });
            console.log("col mayor cero count len : ", this.columnas);
          }
        });
    },

    async getdatosrep() {
      console.log("fecha: ", this.expire);
      console.log("id area : ", this.idarea);
      console.log("id colum : ", this.idcol);
      console.log("id workg : ", this.id_workgroup);
      //const str_url =
      //  "https://testpsism.000webhostapp.com/db/reprutinas.php";

      if (this.idarea == 0 && this.idcol == 0) {
        console.log(" if 0 y 0 : getall ");
        //this.getdatosall();
        alert("Por favo de seleccionar una Area");
        this.dialog = false;
      } else {
        if (this.id_workgroup > 0) {
          console.log(" if 1 y 1 : getcols ");
          this.getdatosareascol();
        } else {
          console.log(" if 1 y 0 : getarea ");
          this.getdatoarea();
        }
      }

      //console.log('idconfig : ', this.idconfig);

      /*await axios
        .get(str_url, {
          params: {
            request: 4,
            fecharep: this.expire,
          },
        })
        .then((response) => {
          this.rutinas =  response.data;
          console.log("count len : ", response.data);
          
        });*/
    },

    async getdatosall() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/repincidencias.php";

      await axios
        .get(str_url, {
          params: {
            request: 1,
            fecharep: this.expire,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getall : ", response.data);
        });
    },

    geturl(imagen_url) {
      if (imagen_url == null) {
        //console.log('vuelta: ', vuelta);
        return "red";
      } else {
        return "green";
      }
    },

    async getdatoarea() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/repincidencias.php";
      console.log("str url : ", str_url);

      await axios
        .get(str_url, {
          params: {
            request: 2,
            fecharep: this.expire,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getarea : ", response.data);
        });
    },

    async getdatosareascol() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/repincidencias.php";

      await axios
        .get(str_url, {
          params: {
            request: 3,
            fecharep: this.expire,
            idarea: this.idarea,
            idcol: this.id_workgroup,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getareacol : ", response.data);
        });
    },
  },
  mounted() {
    var pakainfo_dt = new Date();
    var month = ("0" + (pakainfo_dt.getMonth() + 1)).slice(-2);
    var date = ("0" + pakainfo_dt.getDate()).slice(-2);
    var year = pakainfo_dt.getFullYear();
    this.expire = year + "-" + month + "-" + date;
    this.placeholder = year + "-" + month + "-" + date;
  },
  created() {
    this.getAreas();
  },
};
</script>