<template>
  <div class="reprutina">
    <v-row align="center" class="list px-3 mx-auto">
      <v-col cols="12" sm="12">
        <v-container fluid>
          <v-row align="center" class="list px-3 mx-auto">
            <v-col class="d-flex" cols="12" md="3">
              <v-select
                v-model="idarea"
                @change="getWorkGroup()"
                :items="areas"
                item-text="name"
                item-value="id"
                label="Selecciona Area"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="id_workgroup"
                :no-data-text="textnodata"
                :items="workgroups"
                 item-text="name"
                 item-value="id_workgroup"
                 label="Seleccione Grupo"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="showMenu"
                :close-on-content-click="false"
                :return-value.sync="expire"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="expire"
                    class="mr-2"
                    v-bind="attrs"
                    :placeholder="placeholder"
                    required
                    :append-icon="'mdi-calendar-check'"
                    :rules="rules"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="expire"
                  no-title
                  scrollable
                  locale="ES-es"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showMenu = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu.save(expire)"
                    >Aceptar</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn small @click="getdatosrep()"> Buscar </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" class="list px-3 mx-auto">
            <v-col class="d-flex" cols="12" md="3">
               <v-select
                v-model="selecturno"
                :items="turno"
                item-text="text"
                item-value="id"
                label="Seleccione Turno"
                multiple>
               </v-select>
            </v-col>  
          </v-row>  

          <v-col cols="12" sm="12">
             <div class="text-center">
     <v-chip
      class="ma-2"
      color="green"
      text-color="white"
    >
      Registro en tiempo
    </v-chip>
    <v-chip
      class="ma-2"
      color="yellow"
      text-color="white"
    >
      Registro fuera de tiempo
    </v-chip>
      
    <v-chip
      class="ma-2"
      color="red"
      text-color="white"
    >
      Sin Registro
    </v-chip>
  </div>
            <v-card class="mx-auto" tile>
              <v-card-title>Rutinas  </v-card-title>
              <v-data-table
                :headers="headers"
                :items="rutinas"
                :no-data-text="tablenodata"
                class="elevation-1"
                :footer-props="{
                  'items-per-page-text': 'Registros por pagina',
                }"
              >
                <template v-slot:[`item.fechahora`]="{ item }">
                  <v-chip :color="getColor(item.indicador)" dark>
                    {{ item.fechahora }}
                  </v-chip>
                </template>
                <template v-slot:[`item.imagen_url`]="{ item }">
      <v-icon v-if="item.imagen_url"
        small
        class="mr-2"
        @click="view(item.imagen_url)"
      >
        mdi-image
      </v-icon>
    </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog2" max-width="400">
    <v-card  max-width="400">
      <v-img v-bind:src="image" width="100%" height="100%"></v-img>
    </v-card>
  </v-dialog>
    
    <v-dialog
      v-model="dialog"
      persistent
      content-class="loading-dialog"
      max-width="400"
    >
      <!--<v-layout row justify-center align-center>-->
      <v-card>
        <div class="text-center">Buscando Información ...</div>

        <v-card-title class="layout justify-center">
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="orange"
          ></v-progress-circular>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
//const axios = require("axios");
import axios from "axios";
export default {
  name: "Reprutina",
  data: () => ({
    turno: [{id: 1, text: "Turno 1"}, {id: 2 , text: "Turno 2"}, {id: 3, text: "Turno 3"}],
    selecturno: [],
    rutinas: [],
    textnodata: "Sin Datos",
    tablenodata: "Sin Datos",
    dialog: false,
    dialog2: false,
    headers: [
      { text: "Columna", value: "namecol", sortable: false },
//      { text: "Contenedor", value: "nametipocontenedor", sortable: false },
      { text: "Contenido", value: "namemat", sortable: false },
      { text: "NoContenedor", value: "contenum", sortable: false },
      { text: "Rango", value: "rango", sortable: false },
      { text: "Fecha y Hora", value: "fechahora", sortable: false },
      { text: "Incidencia", value: "incidencia", sortable: false },
      { text: "Imagen", value: "imagen_url", sortable: false },
      { text: "Turno", value: "turno", sortable: false },
      { text: "Vuelta", value: "vuelta", sortable: false },
    ],
    expire: "",
    placeholder: "",
    rules: [(v) => !!v || "This field is required"],
    showMenu: false,
    menu: false,
    modal: false,
    menu2: false,
    idarea: 0,
    areas: [],
    idcol: 0,
    columnas: [],
    country: "",
    idconfig: 0,
    textin: "AND turno IN (",
    textvalue: "",
    textsqlin: "",
    id_workgroup: 0,
    workgroups: [],
    image: "",
    Str_imagen: ""

  }),

  methods: {
    view (imagen_url) {
      this.dialog2 = true;
      this.Str_imagen = imagen_url.substr(2,imagen_url.length-2);
      //console.log('strurl imagen :. ' . this.Str_imagen);
      this.image = encodeURI('https://psisantamonica.mx/daimler' + this.Str_imagen);
    },
    getColor(indicador) {
      if (indicador == 0) {
        return "red";
      } else {
        if (indicador == 1) {
          return "yellow";
        } else {
          return "green";
        }
      }
    },

    async getAreas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=1";

      await axios.get(str_url).then((response) => {
        this.areas = response.data;
        console.log("areas: ", this.areas);
      });
    },

    async getWorkGroup() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php";

      await axios
        .get(str_url, {
          params: {
            request: 4,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          console.log('response.data; ', response.data)
          this.workgroups = response.data;
          console.log("work count len : ", this.columnas.length);
          
          this.idwork = 0;
            

          if (this.workgroups.length > 0) {
            
            this.workgroups.push({
            id_workgroup: 0,
            name: 'todas los grupos'
            });
            
            this.workgroups.sort(function(a, b) {
              var codigoA = a.id_workgroup; // ignore upper and lowercase
        var codigoB = b.id_workgroup; // ignore upper and lowercase
        return codigoA - codigoB;
            });
console.log("col mayor cero count len : ", this.workgroups);
          }
        });
    },



    async getColumnas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=2";

      await axios
        .get(str_url, {
          params: {
            request: 2,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          this.columnas = response.data;
          console.log("count len : ", this.columnas.length);

          this.idcol = 0;

          if (this.columnas.length > 0) {
            this.columnas.push({
              idcol: 0,
              name: "todas las Columnas",
            });

            this.columnas.sort(function (a, b) {
              var codigoA = a.idcol; // ignore upper and lowercase
              var codigoB = b.idcol; // ignore upper and lowercase
              return codigoA - codigoB;
            });
            console.log("col mayor cero count len : ", this.columnas);
          }
        });
    },

    async getdatosrep() {
      console.log("fecha: ", this.expire);
      console.log("id area : ", this.idarea);
      console.log("id colum : ", this.idcol);
      console.log("id workg : ", this.id_workgroup);
      console.log("turnos : ",  this.selecturno);
      const current = new Date();
      const timex = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      console.log("time : ",  timex);
      this.dialog = true;
      this.rutinas = [];
      this.textsqlin = "";
      this.textvalue = "";
      if (this.selecturno.length > 0) {
        this.selecturno.forEach((t) => {
        console.log('elemento : ' , t)
        if (this.textvalue.length == 0) {
          this.textvalue = t;
        }else{
          this.textvalue = this.textvalue +   "," + t;
        }
      })
      this.textsqlin = this.textin + this.textvalue + ")";
      }
      
      console.log("textin: ", this.textin , " textvalue: " , this.textsqlin)
      //const str_url =
      //  "https://testpsism.000webhostapp.com/db/reprutinas.php";

  
      if (this.idarea == 0 && this.idcol == 0) {
        console.log(" if 0 y 0 : getall ");
        //this.getdatosall();
        alert("Por favo de seleccionar una Area");
        this.dialog = false;
      } else {
        if(this.id_workgroup > 0){
          console.log(" if 1 y 1 : getcols ");
          this.getdatosareascol();
        } else {
          console.log(" if 1 y 0 : getarea ");
          this.getdatoarea();
        }
      }

      //console.log('idconfig : ', this.idconfig);

      /*await axios
        .get(str_url, {
          params: {
            request: 4,
            fecharep: this.expire,
          },
        })
        .then((response) => {
          this.rutinas =  response.data;
          console.log("count len : ", response.data);
          
        });*/
    },

    async getdatosall() {
      const str_url = "https://psisantamonica.mx/daimler/db/reprutinas.php";

      await axios
        .get(str_url, {
          params: {
            request: 1,
            fecharep: this.expire,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getall : ", response.data);
        });
    },

    async getdatoarea() {
      const str_url = "https://psisantamonica.mx/daimler/db/reprutinas.php";
      console.log("str url : ", str_url);

      await axios
        .get(str_url, {
          params: {
            request: 2,
            fecharep: this.expire,
            idarea: this.idarea,
            inturno: this.textsqlin,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getarea : ", response.data);
          this.dialog = false;
        });
    },

    async getdatosareascol() {
      const str_url = "https://psisantamonica.mx/daimler/db/reprutinas.php";

      await axios
        .get(str_url, {
          params: {
            request: 3,
            fecharep: this.expire,
            idarea: this.idarea,
            //idcol: this.idcol,
            idcol: this.id_workgroup,
            inturno: this.textsqlin,
          },
        })
        .then((response) => {
          this.rutinas = response.data;
          console.log("getareacol : ", response.data);
          this.dialog = false;
        });
    },
  },
  created() {
    this.getAreas();
  },
  mounted() {
    var pakainfo_dt = new Date();
    var month = ("0" + (pakainfo_dt.getMonth() + 1)).slice(-2);
    var date = ("0" + pakainfo_dt.getDate()).slice(-2);
    var year = pakainfo_dt.getFullYear();
    this.expire = year + "-" + month + "-" + date;
    this.placeholder = year + "-" + month + "-" + date;
  },
};
</script>