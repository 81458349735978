<script>
  import { Bar } from 'vue-chartjs'


  export default {
    extends: Bar,
    props: {
      chartData1: {
        type: Array,
        required: true,
      
      },
      chartData2: {
        type: Array,
        required: true,
      
      },
      chartData3: {
        type: Array,
        required: true,
      
      },
      chartData4: {
        type: Array,
        required: true,
      
      },
      chartData5: {
        type: Array,
        required: true,
      
      },
      chartData6: {
        type: Array,
        required: true,
      
      },
      chartData7: {
        type: Array,
        required: true,
      
      },
      chartLabels: {
        type: Array,
        required: true
      },
    },
    data () {
      return {
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
              gridLines: {
                display: true
              },
              stacked:true,
            }],
            xAxes: [ {
                gridLines: {
                display: false
              },
              stacked:true,
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
      this.renderChart({
        labels: this.chartLabels,
        datasets: [
          {
            categoryPercentage: 0.4,
            label: 'Plastico',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#f8fc03',
            data: this.chartData1
          },
          {
            categoryPercentage: 0.4,
            label: 'RP Basura',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#fc0505',
            data: this.chartData2
          },
        
          {
            categoryPercentage: 0.4,
            label: 'Carton',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#5c2004',
            data: this.chartData3
          },
          {
            categoryPercentage: 0.4,
            label: 'Basura',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#1aa80a',
            data: this.chartData4
          },
          {
            categoryPercentage: 0.4,
            label: 'Papel',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#ffffff',
            data: this.chartData5
          },
          {
            categoryPercentage: 0.4,
            label: 'Aluminio',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#a103fc',
            data: this.chartData6
          },
          {
            categoryPercentage: 0.4,
            label: 'Metal',
            borderColor: '#000000',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            backgroundColor: '#117aab',
            data: this.chartData7
          },
        ],
      }, this.options)
    },
  
  }
</script>