<template>
  <div class="repgraf">
    <v-row align="center" class="list px-3 mx-auto">
      <v-col cols="12" sm="12">
        <v-container fluid>
          <v-row align="center" class="list px-3 mx-auto">
            <v-col class="d-flex" cols="12" md="3">
              <v-select
                v-model="idarea"
                @change="getWorkGroup()"
                :items="areas"
                item-text="name"
                item-value="id"
                label="Selecciona Area"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
  <v-select 
    v-model="idwork"
    :no-data-text="textnodata"
     :items="workgroups"
          item-text="name"
          item-value="id_workgroup"
          label="Seleccione Grupo">
          
              
  </v-select>          </v-col>
  <!--          <v-col cols="12" md="2">
  <v-select 
    v-model="idcol"
    :no-data-text="textnodata"
     :items="columnas"
          item-text="name"
          item-value="idcol"
          label="Seleccione Columna">
          
              
  </v-select>          </v-col>//-->
  
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="showMenu"
                :close-on-content-click="false"
                :return-value.sync="expire"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="expire"
                    class="mr-2"
                    v-bind="attrs"
                    :placeholder="placeholder"
                    required
                    :append-icon="'mdi-calendar-check'"
                    :rules="rules"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="expire"
                  no-title
                  scrollable
                  locale="ES-es"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="showMenu = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="$refs.menu.save(expire)"
                    >Aceptar</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn small @click="getdatosrep()"> Ejecutar </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="loaded">
            <v-col cols="12" sm="12">
              <v-container mt-5>
              </v-container>
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card color="white" :elevation="hover ? 16 : 2">
                  <v-row>
                    <v-container>
                      <ColumsChart
                        :chartData="danicol"
                        :chartData2="danicol2"
                        :chartLabels="assetcol"
                      />
                    </v-container>
                  </v-row>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-row v-if="loaded1">
            <v-col cols="6" sm="6">
              <v-container mt-5>
              </v-container>
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card color="white" :elevation="hover ? 16 : 2">
                  <v-row>
                    <v-container>
                      <ColumsChart4
                        :chartData1="datos1"
                        :chartData2="datos2"
                        :chartData3="datos3"
                        :chartData4="datos4"
                        :chartData5="datos5"
                        :chartData6="datos6"
                        :chartData7="datos7"
                        :chartLabels="assetcol"
                      />
                    </v-container>
                  </v-row>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ColumsChart from "@/components/AreaChart2";
import ColumsChart4 from "@/components/AreaChart4";
//const axios = require("axios");
import axios from "axios";
export default {
  name: "Repgraf",
  components: {
    ColumsChart,
    ColumsChart4,
  },
  data: () => ({
    showMenu: false,
    placeholder: '',
    rules: [(v) => !!v || "This field is required"],
    expire: '',
    idarea: 0,
    areas: [],
    loaded: false,
    loaded1: false,
    loaded2: false,
    captionAre: "Areas",
    captionColumna: "Columnas",
    dani: [],
    asset: [],
    danicol: [],
    assetcol: [],
    danicol2: [],
    assetcol2: [],
    datos1: [],
    datos2: [],
    datos3: [],
    datos4: [],
    datos5: [],
    datos6: [],
    datos7: [],
    datos8: [],
    datos9: [],
    datos10: [],
    labelArea: "Incidencias x Area",
    id_area_count: 0,
    fechahoy: "",
    columnas: [],
    workgroups: [],
    idcol: 0,
    idwork: 0,
    textnodata :'Sin Datos',
  }),
  methods: {
      async getAreas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=1";
        
          await axios.get(str_url).then(response => {
            this.areas = response.data;
            console.log('areas: ', this.areas)
            })
        
  
    },
    async getColumnas() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php?request=2";

      await axios
        .get(str_url, {
          params: {
            request: 2,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          this.columnas = response.data;
          console.log("count len : ", this.columnas.length);
          
          this.idcol = 0;
            

          if (this.columnas.length > 0) {
            
            this.columnas.push({
            idcol: 0,
            name: 'todas las Columnas'
            });
            
            this.columnas.sort(function(a, b) {
              var codigoA = a.idcol; // ignore upper and lowercase
        var codigoB = b.idcol; // ignore upper and lowercase
        return codigoA - codigoB;
            });
console.log("col mayor cero count len : ", this.columnas);
          }
        });
    },
    async getColumnasxgrupo() {

      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php";

      await axios
        .get(str_url, {
          params: {
            request: 5,
            idarea: this.idarea,
            idwork: this.idwork,
          },
        })
        .then((response) => {
          this.columnas = response.data;
          console.log("count len : ", this.columnas.length);
          this.idcol = 0;
            if (this.columnas.length > 0) {
            
            this.columnas.push({
              idcol: 0,
              name: 'todas las Columnas'
            });
            
            this.columnas.sort(function(a, b) {
              var codigoA = a.idcol; // ignore upper and lowercase
              var codigoB = b.idcol; // ignore upper and lowercase
              return codigoA - codigoB;
            });
            console.log("col mayor cero count len : ", this.columnas);
          }
        });
    },
    async getWorkGroup() {
      const str_url =
        "https://psisantamonica.mx/daimler/db/dblist_areas.php";

      await axios
        .get(str_url, {
          params: {
            request: 4,
            idarea: this.idarea,
          },
        })
        .then((response) => {
          console.log('response.data; ', response.data)
          this.workgroups = response.data;
          console.log("work count len : ", this.columnas.length);
          
          this.idwork = 0;
            

          if (this.workgroups.length > 0) {
            
            this.workgroups.push({
            id_workgroup: 0,
            name: 'todas los grupos'
            });
            
            this.workgroups.sort(function(a, b) {
              var codigoA = a.id_workgroup; // ignore upper and lowercase
        var codigoB = b.id_workgroup; // ignore upper and lowercase
        return codigoA - codigoB;
            });
console.log("col mayor cero count len : ", this.workgroups);
          }
        });
    },
    async getdatosrep() {
    this.loaded = false
    this.loaded1 = false
      if (this.idwork == 0){
        
        try{
          const str_url = "https://psisantamonica.mx/daimler/db/graficaxarea.php";
          console.log('url : ', str_url);
      
          console.log('this.idarea: ',this.idarea)
          console.log('this.dateTimeNewFormat: ',this.expire)
          await axios.get(str_url,{
            params: {
              date_reg: this.expire,
              id_area: this.idarea,
              },})
              .then( response => {
                console.log('data : ' ,response.data);
                this.danicol = response.data['grapcolumn'].map(item => item.valor)
                this.assetcol = response.data['grapcolumn'].map(item => item.name)
                this.danicol2 = response.data['grapcolumn1'].map(item => item.valor)
                console.log('columnas valor : ' , this.danicol);
                console.log('columnas name: ' , this.assetcol);
                console.log('columnas valor2 : ' , this.danicol2);
                })      
                
          this.loaded = true
          } catch (e) {
            console.error(e)
            }
      }else{
        this.loaded1 = false
        try{
          const str_url = "https://psisantamonica.mx/daimler/db/graficaxgrupo.php";
          console.log('url : ', str_url);
      
          console.log('this.idarea: ',this.idarea)
          console.log('this.expire: ',this.expire)
          console.log('this.id_work: ',this.idwork)
          await axios.get(str_url,{
            params: {
              date_reg: this.expire,
              id_area: this.idarea,
              id_workgroup: this.idwork,
              },})
              .then( response => {
                console.log('data : ' ,response.data);
                
                this.assetcol = response.data['grapcolumn1'].map(item => item.name)
                this.datos1 = response.data['grapcolumn1'].map(item => item.valor)
                this.datos2 = response.data['grapcolumn2'].map(item => item.valor)
                this.datos3 = response.data['grapcolumn3'].map(item => item.valor)
                this.datos4 = response.data['grapcolumn4'].map(item => item.valor)
                this.datos5 = response.data['grapcolumn5'].map(item => item.valor)
                this.datos6 = response.data['grapcolumn6'].map(item => item.valor)
                this.datos7 = response.data['grapcolumn7'].map(item => item.valor)
                this.datos8 = response.data['grapcolumn8'].map(item => item.valor)
                this.datos9 = response.data['grapcolumn9'].map(item => item.valor)
                this.datos10 = response.data['grapcolumn10'].map(item => item.valor)
                console.log('columnas label: ' , this.assetcol);
                console.log('columnas valor1 : ' , this.datos1);
                console.log('columnas valor1 : ' , this.datos2);
                console.log('columnas valor1 : ' , this.datos3);
                console.log('columnas valor1 : ' , this.datos4);
                console.log('columnas valor1 : ' , this.datos5);
                })      
                
          this.loaded1 = true
    } catch (e) {
      console.error(e)
    }

      }
    
    },
  },
  mounted() {
      var pakainfo_dt = new Date();
      var month = ('0' + (pakainfo_dt.getMonth() + 1)).slice(-2);
      var date = ('0' + pakainfo_dt.getDate()).slice(-2);
      var year = pakainfo_dt.getFullYear();
       this.expire = year + '-' + month + '-' + date;
       this.placeholder = year + '-' + month + '-' + date;

  },
  created() {
    this.getAreas();
  },
};
</script>