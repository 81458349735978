<template>
  <div class="backdrop" @click="closeModal">
    <div class="modal" >
      <img v-bind:src="text" width="480" height="480" >
    </div>
  </div>
</template>

<script>
export default {
  props: ['heading', 'text', 'theme'],
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>

<style>
  .modal {
    width: 500px;
    height: 500px;
    padding: 10px;
    margin: 5% 0% 0% 20%;
    

    background: white;
    border-radius: 10px;
  }
  .backdrop {
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
  }
  .modal h1 {
    color: #03cfb4;
    border: none;
    padding: 0;
  }
  .modal p {
    font-style: normal;
  }
  /* sale styles */
  .modal.sale {
    background: crimson;
    color: white;
  }
  .modal.sale h1 {
    color: white;
  }
</style>